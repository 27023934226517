import { combineReducers } from '@reduxjs/toolkit';
import { eventSlice } from 'src/slices/events/eventSlice';
import { notificationSlice } from 'src/slices/notificationsApp/notificationSlice';
import { userSlice } from 'src/slices/users/userSlice';
import { tagSlice } from 'src/slices/tags/tagSlice';
import { rolSlice } from 'src/slices/roles/rolSlice';
import { newsSlice } from 'src/slices/news/newsSlice';
import { placeSlice } from 'src/slices/places/placeSlice';
import { categorySlice } from 'src/slices/categoryLabels/categorySlice';
import { ticketTypeSlice } from 'src/slices/ticketsType/ticketTypeSlice';
import { rolPermissionSlice } from 'src/slices/rolPermissions/rolPermissionSlice';
import { statisticByClientSlice } from 'src/slices/statistics/ByClient/ByClientSlice';
import { statisticByEventSlice } from 'src/slices/statistics/ByEvent/ByEventSlice';
import { statisticByRRPPSlice } from 'src/slices/statistics/ByRrpp/ByRrppSlice';
import { ticketSlice } from 'src/slices/tickets/ticketSlice';
import { monthSlice } from 'src/slices/months/monthSlice';
import { redimidorSlice } from 'src/slices/redimidores/redimidorSlice';
import { genderSlice } from 'src/slices/genders/genderSlice';
import { authSlice } from 'src/slices/auth/authSlice';
import { stadisticSlice } from 'src/slices/statistics/stadisticsSlice';
import { settingsSlice } from 'src/slices/settings/settingsSlice';

const rootReducer = combineReducers({
  events: eventSlice.reducer,
  redimidores: redimidorSlice.reducer,
  users: userSlice.reducer,
  news: newsSlice.reducer,
  tags: tagSlice.reducer,
  roles:rolSlice.reducer,
  notifications: notificationSlice.reducer,
  places: placeSlice.reducer,
  categories: categorySlice.reducer,
  tickets: ticketSlice.reducer,
  ticketsType: ticketTypeSlice.reducer,
  rolPermissions: rolPermissionSlice.reducer,
  statisticsByClient:  statisticByClientSlice.reducer,
  statisticByEvent: statisticByEventSlice.reducer,
  statisticByRRPP: statisticByRRPPSlice.reducer,
  months: monthSlice.reducer,
  genders: genderSlice.reducer,
  auth: authSlice.reducer,
  stadistic: stadisticSlice.reducer,
  settings: settingsSlice.reducer,
});

export default rootReducer;
