import { RouteObject } from 'react-router';
import Authenticated from 'src/components/Authenticated';
import SidebarLayout from 'src/layouts/SidebarLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import managementRoutes from './management';
import baseRoutes from './base';
import accountRoutes from './account';
import Dashboard from 'src/content/dashboard';
import RoleBasedRoute from './routesConstants';
import { Outlet } from 'react-router-dom';
import { rolesApp } from 'src/utils/rolesConstants';
import { Suspense, lazy } from 'react';
import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

//Accede a url terminos y condiciones o politicas de privacidad sin estar logeado en la app
const TermsAndConditions = Loader(lazy(() => import('src/policies/tyc-v2')));
const PrivacyPolicie = Loader(lazy(() => import('src/policies/privacy-policy')));

const router: RouteObject[] = [
  {
    path: 'account',
    children: accountRoutes
  },
  {
    path: '',
    element: <BaseLayout />,
    children: baseRoutes
  },
  {
    path: 'dashboard',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Dashboard />
      },
      {
        path: 'management',
        element: (
            <RoleBasedRoute rolesCustom={[rolesApp.SUPERADMIN, rolesApp.PRODUCTOR, rolesApp.POS, rolesApp.ADMIN]} element={<Outlet />} />
          ),
        children: managementRoutes
      }
    ]
  },
  {
    path: 'tyc-v2',
    element: <TermsAndConditions />
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicie />
  },
  {
    path: 'privacy-policie',
    element: <PrivacyPolicie />
  },
  {
    path: 'tyc',
    element: <PrivacyPolicie />
  },
];

export default router;
