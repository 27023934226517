import { createSlice } from '@reduxjs/toolkit';
import { Redimidor } from 'src/models/redimidor';

interface RedimidorState {
  isLoadingRole: boolean;
  redimidores: Redimidor[];
  changeRedimidor: null;
  isErrorMessage: string;
  statusCode: number;
}

const initialState: RedimidorState = {
  isLoadingRole: false,
  redimidores: [],
  changeRedimidor: null,
  isErrorMessage: '',
  statusCode: 201
};

export const redimidorSlice = createSlice({
  name: 'redimidor',
  initialState,
  reducers: {
    startLoadingRedimidores: (state) => {
      state.isLoadingRole = true;
    },
    setRedimidores: (state, action) => {
      state.redimidores = action.payload
      state.isLoadingRole = false;
    },
    setChangeRedimidor: (state, action) => {
      state.changeRedimidor = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingRedimidores: (state) => {
      state.isLoadingRole = false;
    },
  }
});

export const {
  startLoadingRedimidores,
  setRedimidores,
  setChangeRedimidor,
  setError,
  stopLoadingRedimidores,
} = redimidorSlice.actions;