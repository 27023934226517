import { createSlice } from '@reduxjs/toolkit';
import { SettingsData } from 'src/models/settings';

interface SettingsState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  settings: SettingsData;
  activeTab: number;
  page: number;
  limit: number;
  changeSettings: null;
}

const initialState: SettingsState = {
  isLoading: false,
  statusCode: 200,
  isErrorMessage: '',
  settings: {
    config: {
      allowTickets0: false,
      appPaymentGateway: 'pago',
      changeCountryCode: false,
      countryCode: '+54',
      documentTypes: ['DNI'],
      emailInfoApp: 'definir@mail.com',
      facebookLogin: false,
			hasLoyalyCategories: 'inactive',
      hasLoyalyProgram: 'inactive',
      hasMembershipProgram: 'inactive',
      hasTicketTransfer: 'inactive',
      instagramInfoApp: '@qp',
			loginVerificationReq: false,
      mercadoPagoSandbox: false,
      popularCountries: ['ar', 'br'],
      screenCapture: 'inactive',
      serviceCharge: 0,
      termsPdfUrl: '',
      unregisteredSell: false,
			walletMode: 'inactive',
    },
    rrpp: {
      giftAvailable: true
    },
    1: {
      androidStoreUrl: 'asignar',
      iosStoreUrl: 'asignar',
      maintenance: {
        isMaintenance: true,
        message:
          'La app se encuentra en mantenimiento, intente nuevamente más tarde'
      },
      minAndroidBinaryVersion: '1.0.0',
      minIosBinaryVersion: '1.0.0'
    }
  },
  activeTab: 1,
  page: 0,
  limit: 25,
  changeSettings: null
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    startLoadingSettings: (state) => {
      state.isLoading = true;
    },
    setSettings: (state, action) => {
      state.isLoading = false;
      state.settings = action.payload;
    },
    setChangeSettings: (state, action) => {
      state.changeSettings = action.payload;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingSettings: (state) => {
      state.isLoading = false;
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    }
  }
});

export const {
  startLoadingSettings,
  setSettings,
  setError,
  setChangeSettings,
  stopLoadingSettings,
	setActiveTab
} = settingsSlice.actions;
