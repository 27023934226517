import { createSlice } from '@reduxjs/toolkit';
import { set } from 'nprogress';
import { Redimidor } from 'src/models/redimidor';
import { RolesPaginated, UserByRole } from 'src/models/roles';

interface RolState {
  isLoading: boolean;
  isErrorMessage: string;
  statusCode: number;
  roles: Redimidor[];
  activeRol: Redimidor | {};
  rolPaginated: RolesPaginated[];
  page: number;
  limit: number;
  total: number;
  changeRol: null;
  permission: any;
  changeUsers: null;
  totalUsers: number;
  currentPage: number;
  totalPages: number;
  roleUsers: UserByRole[];
  roleId: string;
  usersAdded: [];
  userFilter: string;
  isRequestPending: boolean;
  errorMessage: string;
  provisionalRoleId: number;
  isLoadingUsers: boolean;
}

const initialState: RolState = {
  isLoading: false,
  statusCode: 201,
  isErrorMessage: '',
  roles: [],
  activeRol: {},
  rolPaginated: [],
  page: 1,
  limit: 25,
  total: 0,
  changeRol: null,
  permission: [] || {},
  changeUsers: null,
  totalUsers: 0,
  currentPage: 0,
  totalPages: 0,
  roleUsers: [],
  roleId: '',
  usersAdded: [],
  userFilter: '',
  isRequestPending: false,
  errorMessage: '',
  provisionalRoleId: 0,
  isLoadingUsers: false,
};

export const rolSlice = createSlice({
  name: 'rol',
  initialState,
  reducers: {
    startLoadingRoles: (state) => {
      state.isLoading = true;
    },
    setRoles: (state, action) => {
      state.isLoading = false;
      state.roles = [...action.payload];
    },
    setRolesPaginated: (state, action) => {
      state.isLoading = false;
      state.page = action.payload.currentPage;
      state.total = action.payload.totalRoles;
      state.rolPaginated = action.payload.results;
    },
    setChangeRole: (state, action) => {
      state.isLoading = false;
      state.changeRol = action.payload.results;
    },
    setError: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.isErrorMessage = message;
    },
    stopLoadingRoles: (state) => {
      state.isLoading = false;
    },
    setPermissionRole: (state, action) => {
      state.permission = action.payload;
    },
    setChangeUsers: (state, action) => {
      state.changeUsers = action.payload.results;
    },
    setRoleUsers: (state, action) => { 
      state.isLoadingUsers = false;
      state.currentPage = action.payload.currentPage;
      state.totalPages = action.payload.totalPages
      state.roleUsers = action.payload.results;
    },
    setRoleId:(state, action) => {
      state.roleId = action.payload;
    },
    setClearRoleUsers:(state) => {
      state.currentPage= 0;
      state.totalPages= 0;
      state.roleUsers= [];
      state.totalUsers= 0;
      state.roleId= '';
      state.userFilter= '';
      state.isRequestPending= false;
    },
    setAddUsers: (state, action) => {
      state.isLoadingUsers= false;
      state.usersAdded = action.payload.usersAdded;
    },
    setUserFilter: (state, action) => {
      state.userFilter = action.payload;
    },
    setIsRequestPending: (state, action) => {
      state.isRequestPending = action.payload;
    },
    setErrorRoleUsers: (state, action) => {
      const message = action.payload.message;
      state.statusCode = action.payload.status;
      state.errorMessage = message;
    },
    setProvisionalRoleId: (state, action) => {
      state.provisionalRoleId = action.payload;
    },
    startLoadingUsers: (state) => {
      state.isLoadingUsers = true;
    },
    stopLoadingUsers: (state) => {
      state.isLoadingUsers = false;
    }
  },
});

export const {
  startLoadingRoles,
  setRoles,
  setRolesPaginated,
  setError,
  setChangeRole,
  stopLoadingRoles,
  setPermissionRole,
  setChangeUsers,
  setRoleUsers,
  setRoleId,
  setClearRoleUsers,
  setAddUsers,
  setUserFilter,
  setIsRequestPending,
  setErrorRoleUsers,
  setProvisionalRoleId,
  startLoadingUsers,
  stopLoadingUsers,
} = rolSlice.actions;